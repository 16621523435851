import React from "react";

// reactstrap components
import "../assets/css/swagger-ui.css"
import "../assets/css/style.css"
// core components
import AppNavbar from "components/Navbars/AppNavbar.js";



class Index extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <AppNavbar />
        <main ref="main"></main>
      </>
    );
  }
}

export default Index;
