import React from "react";
import { Link } from "react-router-dom";
import { emailService} from 'services/email.service';
import Player from '../views/Player'
// reactstrap components
import {
    Badge,
    Button,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert,
    Modal,
    Collapse,
    Nav, NavItem, NavLink,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Breadcrumb, BreadcrumbItem,
    Spinner, CardGroup, CardFooter
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Collapsible from 'react-collapsible';
class Event extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <AppNavbar />
                <main className="profile-page demo-page" ref="main">
                    <section className="section-shaped my-0  ">
                        <div className="breadcrumbs-wrap">
                            <Breadcrumb className="breadcrumbs-cust">
                                <BreadcrumbItem   active>Home</BreadcrumbItem>
                            </Breadcrumb>
                            <Breadcrumb>
                                <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                                <BreadcrumbItem active>Guide</BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </section>
                    <section className="section demo-section">

                        <Container>
                            <Row className="justify-content-center">
                                <p className="about-txt1">HPS attends many of the payments industry's leading exhibitions, conferences and trade shows across the globe. These provide a fantastic opportunity to meet with our experts and to learn more about HPS and how we can help your business..</p>
                            </Row>
                            <CardGroup className="card-group-event">
                                <Card className="card-event">
                                    <CardImg
                                        alt="
                                        Africa Financial Industry Summit 2022"
                                        src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Lome%CC%81_0.jpg?itok=veJt6lco"
                                        top
                                        width="100%"
                                    />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            Africa Financial Industry Summit 2022
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            28 - 29 November 2022 | Abidjan
                                        </CardSubtitle>
                                        <CardText  className="card-event-body">
                                            HPS, the leading provider of payment solutions, is delighted to announce its participation to AFIS Annual Summit that will take place in November 28 – 29, 2022 in Lomé.
                                        </CardText>

                                    </CardBody>
                                    <CardFooter className="card-event-footer">
                                        <Button className="btn-event-more">
                                            More information >
                                        </Button>
                                    </CardFooter>
                                </Card>
                                <Card className="card-event">
                                    <CardImg
                                        alt="Next Fintech Forum 2022"
                                        src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Abidjan_0.jpg?itok=bv4by7Vn"
                                        top
                                        width="100%"
                                    />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            Next Fintech Forum 2022
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            23 - 24 November 2022 | Abidjan
                                        </CardSubtitle>
                                        <CardText  className="card-event-body">
                                            HPS, the leading provider of payment solutions, is delighted to announce its participation to Next Fintech Forum held in November 23 – 24, 2022 in Abidjan.
                                        </CardText>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                More information >
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                                <Card className="card-event">
                                    <CardImg
                                        alt="Salon Monétique Régional GIM-UEMOA 2022"
                                        src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Dakar_0.jpg?itok=D9Y3i6Gh"
                                        top
                                        width="100%"
                                    />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            Salon Monétique Régional GIM-UEMOA 2022
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            3-4 November 2022 | Dakar
                                        </CardSubtitle>
                                        <CardText  className="card-event-body">
                                            HPS is participating to GIM- UEMOA as editor partner. This event will take place in Dakar in 3-4 November 2022. HPS will is the opportunity to network with our partners in Africa and leverage on the newest trends.
                                        </CardText>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                More information >
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                            <CardGroup className="card-group-event">
                                <Card className="card-event">
                                    <CardImg
                                        alt="Seamless KSA 2022"
                                        src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Riyadh.png?itok=cJ9ZB1Jz"
                                        top
                                        width="100%"
                                    />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            Seamless KSA 2022
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            2 - 3 November 2022 | Riyadh
                                        </CardSubtitle>
                                        <CardText  className="card-event-body">
                                            HPS is happy to be part of the Platinum Sponsor of Seamless KSA first edition that will take place in Riyadh, November 2-3
                                        </CardText>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                More information >
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                                <Card className="card-event">
                                    <CardImg
                                        alt="Nexo Annual Conference 2022"
                                        src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Madrid.jpg?itok=Ww4yj8T_"
                                        top
                                        width="100%"
                                    />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            Nexo Annual Conference 2022
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            25 - 26 October 2022 | Madrid
                                        </CardSubtitle>
                                        <CardText  className="card-event-body">
                                            HPS, the leading provider of payment solutions, is delighted to announce its participation to Nexo Annual Conference in Madrid on October 26th, 2022.
                                        </CardText>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                More information >
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                                <Card className="card-event">
                                    <CardImg
                                        alt="Seamless Middle East 2022"
                                        src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Dubai.jpg?itok=MyIAolxw"
                                        top
                                        width="100%"
                                    />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            Seamless Middle East 2022
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            May 31- June 1 2022 | Dubai
                                        </CardSubtitle>
                                        <CardText  className="card-event-body">
                                            HPS, the leading provider of payment solutions, is delighted to announce its participation to Seamless Dubai held in between May 31 – June 1, 2022 in Dubai.
                                        </CardText>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                More information >
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                            <CardGroup className="card-group-event">
                                <Card className="card-event">
                                    <CardImg
                                        alt="FONI 2022"
                                        src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Lome%CC%81.jpg?itok=dvWk7fZk"
                                        top
                                        width="100%"
                                    />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            FONI 2022
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            5 - 6 May 2022 | Lomé
                                        </CardSubtitle>
                                        <CardText  className="card-event-body">
                                            FONI is the newest platform for meetings and sharing experiences, that aims to discuss the impact of banking/financial intermediation activities in the dynamics of financial inclusion.
                                        </CardText>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                More information >
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                                <Card className="card-event">
                                    <CardImg
                                        alt="MoneyLive Summit 2022"
                                        src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/London_0.jpg?itok=luJd4e1R"
                                        top
                                        width="100%"
                                    />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            MoneyLive Summit 2022
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            28 - 29 March 2022 | London
                                        </CardSubtitle>
                                        <CardText  className="card-event-body">
                                            MoneyLIVE Summit is the ultimate event for those passionate about transforming banking and payments. It’s where collaboration is celebrated, views are challenged and visionary ideas come alive.
                                        </CardText>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                More information >
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                                <Card className="card-event">
                                    <CardImg
                                        alt="Africa Pay & ID Expo 2022"
                                        src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Marrakech_0.jpg?itok=yoW3uRyv"
                                        top
                                        width="100%"
                                    />
                                    <CardBody>
                                        <CardTitle tag="h5">
                                            Africa Pay & ID Expo 2022
                                        </CardTitle>
                                        <CardSubtitle
                                            className="mb-2 text-muted"
                                            tag="h6"
                                        >
                                            26 - 27 May 2022 | Marrakesh
                                        </CardSubtitle>
                                        <CardText  className="card-event-body">
                                            HPS is pleased to be the official sponsor of Africa Pay & ID Expo 2022
                                        </CardText>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                More information >
                                            </Button>
                                        </CardFooter>
                                    </CardBody>
                                </Card>
                            </CardGroup>
                        </Container>
                    </section>

                </main>
                <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
                <SimpleFooter />
            </>
        );
    }
}

export default Event;
