
import { authHeader} from 'helpers/auth-header';

export const configService = {
    getAllProducts,
    getAllWsVersion,
    generateSdk,
    getAllWsVersionByProductId,
    getAllSolutions,
    getModulesBySolutionId,
    getModulesBySolutionIdAndApi,
    decyptData,
    encyptData
};

function getAllProducts() {

    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //    return fetch(`${REACT_BASE_URL}/v1/ws/versions/`, requestOptions)
    // fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/ws/versions/`, requestOptions)
    return  fetch(`${REACT_BASE_URL}/v1/ws/products/`, requestOptions)
    .then(response =>{
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                return Promise.reject(response);    
             }
        }
        return response.json().then(data => {
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    return Promise.reject(data);    
                }
                return Promise.reject(data);
            }
            if (response.ok) {
                return data ;
            }
        });
    })
    .then(resp => {
        return resp;
    }).catch(error =>{
        throw(error);
    });
}
function getAllWsVersionByProductId(id) {

    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //    return fetch(`${REACT_BASE_URL}/v1/ws/versions/`, requestOptions)
    // fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/ws/versions/`, requestOptions)
    return  fetch(`${REACT_BASE_URL}/v1/ws/products/`+id+'/versions', requestOptions)
    .then(response =>{
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                return Promise.reject(response);    
             }
        }
        return response.json().then(data => {
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    return Promise.reject(data);    
                }
                return Promise.reject(data);
            }
            if (response.ok) {
                return data ;
            }
        });
    })
    .then(resp => {
        return resp;
    }).catch(error =>{
        throw(error);
    });
}
function getAllWsVersion() {

    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //    return fetch(`${REACT_BASE_URL}/v1/ws/versions/`, requestOptions)
    // fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/ws/versions/`, requestOptions)
    return  fetch(`${REACT_BASE_URL}/v1/ws/versions/`, requestOptions)
    .then(response =>{
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                return Promise.reject(response);    
             }
        }
        return response.json().then(data => {
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    return Promise.reject(data);    
                }
                return Promise.reject(data);
            }
            if (response.ok) {
                return data ;
            }
        });
    })
    .then(resp => {
        return resp;
    }).catch(error =>{
        throw(error);
    });
}

function getAllSolutions(id) {
    const { REACT_APP_PUBLIC_URL } = process.env;
    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //    return fetch(`${REACT_BASE_URL}/v1/ws/versions/`+id+'/modules', requestOptions)
    //   return fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/ws/versions/`+id+'/modules', requestOptions)
    return fetch(`${REACT_BASE_URL}/v1/ws/versions/`+id+'/solutions', requestOptions)
    .then(response =>{
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                return Promise.reject(response);    
             }
        }
        return response.json().then(data => {
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                   return Promise.reject(data);    
                }
                return Promise.reject(data);
            }
            if (response.ok) {
                return data ;
            }
        });
    })
    .then(resp => {
        return resp;
    }).catch(error =>{
        throw(error);
    });
}

function getModulesBySolutionId(id) {
    const { REACT_APP_PUBLIC_URL } = process.env;
    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //    return fetch(`${REACT_BASE_URL}/v1/ws/versions/`+id+'/modules', requestOptions)
    //   return fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/ws/versions/`+id+'/modules', requestOptions)
    return fetch(`${REACT_BASE_URL}/v1/ws/solutions/`+id+'/modules', requestOptions)
    .then(response =>{
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                return Promise.reject(response);    
             }
        }
        return response.json().then(data => {
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                   return Promise.reject(data);    
                }
                return Promise.reject(data);
            }
            if (response.ok) {
                return data ;
            }
        });
    })
    .then(resp => {
        return resp;
    }).catch(error =>{
        throw(error);
    });
}

function getModulesBySolutionIdAndApi(name,id) {
    const { REACT_APP_PUBLIC_URL } = process.env;
    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //    return fetch(`${REACT_BASE_URL}/v1/ws/versions/`+id+'/modules', requestOptions)
    //   return fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/ws/versions/`+id+'/modules', requestOptions)
    return fetch(`${REACT_BASE_URL}/v1/ws/apis?name=`+name+'&solutionId='+id, requestOptions)
    .then(response =>{
        if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
                return Promise.reject(response);    
             }
        }
        return response.json().then(data => {
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                   return Promise.reject(data);    
                }
                return Promise.reject(data);
            }
            if (response.ok) {
                return data ;
            }
        });
    })
    .then(resp => {
        return resp;
    }).catch(error =>{
        throw(error);
    });
}
function generateSdk(languageCode,yamlPath) {
    const { REACT_APP_PUBLIC_URL } = process.env;
    const requestOptions = { method: 'GET', headers: authHeader() };
    let REACT_BASE_URL = document.getElementById("react_base_url").innerHTML ;
    //    return fetch(`${REACT_BASE_URL}/v1/ws/versions/`+id+'/modules', requestOptions)
    //   return fetch(`${process.env.REACT_APP_PUBLIC_URL}/v1/ws/versions/`+id+'/modules', requestOptions)
    return fetch(`${REACT_BASE_URL}/public/generate_client_sdk?language=`+languageCode+'&yamlFile='+yamlPath, requestOptions)
        .then((res) => res.blob())
        .catch(error =>{
                    throw(error);
        });
}

function decyptData(value) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' , 'Accept': 'application/json'},
        body: value
    };
    return fetch(`https://sandbox.connectapi.hps-worldwide.com/PowerCardConnectApi/rest/decryptingData`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);    
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return resp;
        }).catch(error =>{
            throw(error);
        });
}
function encyptData(value) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
        body: value
    };
    return fetch(`https://sandbox.connectapi.hps-worldwide.com/PowerCardConnectApi/rest/encryptingData`, requestOptions)
        .then(response =>{
            return response.text().then(data => {
                if (!response.ok) {
                    if ([401, 403].indexOf(response.status) !== -1) {
                        return Promise.reject(data);    
                    }
                    return Promise.reject(data);
                }
                if (response.ok) {
                    return data ;
                }
            });
        })
        .then(resp => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            return resp;
        }).catch(error =>{
            throw(error);
        });
}