import React from "react";
import { Link } from "react-router-dom";
import { emailService} from 'services/email.service';
import Player from '../views/Player'
// reactstrap components
import {
    Badge,
    Button,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
    Alert,
    Modal,
    Collapse,
    Nav, NavItem, NavLink,
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle,
    Breadcrumb, BreadcrumbItem,
    Spinner, CardGroup, CardFooter
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import Collapsible from 'react-collapsible';
class Videos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <AppNavbar />

                <main className="profile-page demo-page" ref="main">
                    <section className="section-shaped my-0  ">
                        <div className="breadcrumbs-wrap">
                            <Breadcrumb className="breadcrumbs-cust">
                                <BreadcrumbItem   active>Home</BreadcrumbItem>
                            </Breadcrumb>
                            <Breadcrumb>
                                <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                                <BreadcrumbItem active>Guide</BreadcrumbItem>
                            </Breadcrumb>
                        </div>
                    </section>
                    <section className="section demo-section">

                        <Container>
                            <Row className="justify-content-center">
                                <h6>Digital library</h6>
                                <CardGroup className="card-group-event">
                                    <Card className="card-event">
                                        <CardImg
                                            alt="
                                        WeMeeting 21"
                                            src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Marrakech_1.jpg?itok=BjsexvbA"
                                            top
                                            width="100%"
                                        />
                                        <CardBody>
                                            <CardTitle tag="h5">
                                                WeMeeting 21
                                            </CardTitle>
                                            <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                            >
                                                28 - 29 November 2021 | Maroc
                                            </CardSubtitle>
                                            <CardText  className="card-event-body">
                                                HPS, the leading provider of payment solutions, is delighted to announce its participation to AFIS Annual Summit that will take place in November 28 – 29, 2022 in Lomé.
                                            </CardText>

                                        </CardBody>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                               Watch now <i className="fas fa fa-play video-play"></i>
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                    <Card className="card-event">
                                        <CardImg
                                            alt="
                                        WeMeeting 23"
                                            src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Marrakech_1.jpg?itok=BjsexvbA"
                                            top
                                            width="100%"
                                        />
                                        <CardBody>
                                            <CardTitle tag="h5">
                                                WeMeeting 23
                                            </CardTitle>
                                            <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                            >
                                                03 - 06 May 2023 | Maroc
                                            </CardSubtitle>
                                            <CardText  className="card-event-body">
                                                HPS, the leading provider of payment solutions, is delighted to announce its participation to AFIS Annual Summit that will take place in November 28 – 29, 2022 in Lomé.
                                            </CardText>

                                        </CardBody>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                Watch now <i className="fas fa fa-play video-play"></i>
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                    <Card className="card-event">
                                        <CardImg
                                            alt="
                                        WeMeeting 21"
                                            src="https://www.hps-worldwide.com/sites/default/files/styles/portfolio_285x183/public/news/images/Marrakech_1.jpg?itok=BjsexvbA"
                                            top
                                            width="100%"
                                        />
                                        <CardBody>
                                            <CardTitle tag="h5">
                                                WeMeeting 21
                                            </CardTitle>
                                            <CardSubtitle
                                                className="mb-2 text-muted"
                                                tag="h6"
                                            >
                                                28 - 29 November 2021 | Maroc
                                            </CardSubtitle>
                                            <CardText  className="card-event-body">
                                                HPS, the leading provider of payment solutions, is delighted to announce its participation to AFIS Annual Summit that will take place in November 28 – 29, 2022 in Lomé.
                                            </CardText>

                                        </CardBody>
                                        <CardFooter className="card-event-footer">
                                            <Button className="btn-event-more">
                                                Watch now <i className="fas fa fa-play video-play"></i>
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </CardGroup>
                            </Row>
                        </Container>
                    </section>

                </main>
                <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
                <SimpleFooter />
            </>
        );
    }
}

export default Videos;
