
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { Link } from "react-router-dom";
import { emailService } from 'services/email.service';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  UncontrolledCarousel,
  Form,
  Modal,
  Alert,
  Row,
  Col
} from "reactstrap";
import CardLogo from '../assets/img/card.svg'
// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import {ReactSVG} from "react-svg";

const items = [
  {
    src: require("assets/img/carousel-1.png"),
    altText:'',
    caption: '',
    header: ''
  },
  {
    src: require("assets/img/carousel-2.png"),
    altText:'',
    caption: '',
    header: ''
  },
  {
    src: require("assets/img/carousel-3.png"),
    altText:'',
    caption: '',
    header: ''
  },
  {
    src: require("assets/img/carousel-4.png"),
    altText:'',
    caption: '',
    header: ''
  },
  {
    src: require("assets/img/carousel-5.png"),
    altText:'',
    caption: '',
    header: ''
  },
]
class Home extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
       emailSubject:'',
       emailAddress:'',
       fullName:'',
       companyName:'',
       emailBody:'',
       successModal: false,
       failureModal: false,
       currentUser: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

 // state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    sessionStorage.setItem("selected-module","/")
    sessionStorage.setItem("selected-solution","/")
    sessionStorage.setItem("selected-version","/")

  }
  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  isFormValid = () => {
      const {emailSubject, emailAddress,emailBody,companyName,fullName} = this.state
      return emailSubject && emailAddress && emailBody && fullName && companyName;
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  }
  toggleFailModal =  (state,msg) => {
    this.setState({
      [state]: !this.state[state],
      errorMessage : msg
    });
  };
  onSendEmail = () =>{
    const {emailSubject, emailAddress,emailBody,companyName,fullName} = this.state
    emailService.sendEmail(emailSubject,emailAddress,emailBody,companyName,fullName)
    .then(
        rep => {
           this.setState({
             emailSubject:'',
             emailAddress:'',
             companyName:'',
             fullName:'',
             emailBody:'',
             });
             this.toggleModal("successModal");
        },
        (error) => {
          let errorType = new String(error);
          if(error !== undefined && error !== null && !errorType.includes("TypeError")){
            console.log(JSON.stringify(JSON.parse(error)))
            let message = JSON.parse(error);
            this.toggleFailModal("failureModal",message.errorMessage);
          }else{
            this.toggleFailModal("failureModal","Internal error please try again later.");
          }

        }
     );
  }
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  render() {
    return (
      <>
        <AppNavbar />
         {/* on success model */}
         <Modal
          className="modal-dialog-centered"
          isOpen={this.state.successModal}
          toggle={() => this.toggleModal("successModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
             Thank you
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleModal("successModal")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
            <Alert className="alert-default">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message">Email sent with success.</p>
              </span>
             </Alert>
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleModal("successModal")}
            >
              Close
            </Button>
          </div>
        </Modal>
         {/* on failure model */}
         <Modal
          className="modal-dialog-centered"
          isOpen={this.state.failureModal}
          toggle={() => this.toggleFailModal("failureModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title modal-title-error" id="exampleModalLabel">
             Sorry!
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleFailModal("failureModal")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
           {/** <p className="modal-message-error"></p> */}
            <Alert color="warning">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message-error">{this.state.errorMessage}</p>
              </span>
           </Alert>
              
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleFailModal("failureModal")}
            >
              Close
            </Button>
          </div>
        </Modal>
        <main ref="main">
          <div className="position-relative">

            <section className="section section-lg section-shaped pb-250 ">
              <div className="shape shape-style-1 shape-default main-bg">
  
              </div>
              <Container className="py-lg-md d-flex ">
                <div className="col px-0">
                  <Row>
                    <Col lg="7" className="main-sub-bg">
                    <img
                      alt="..."
                      className="img-fluid title_bg "
                      src={require("assets/img/main_title_bg.png")}
                     />
                     <div className="main-section-content">
                     <img
                      alt="..."
                      className="portal-logo"
                      src={require("assets/img/portal_logo.png")}
                     />
                     <h3 className="portal-logo-text">PowerCARD</h3>
                     <h2 className="portal-logo-text-bold">CONNECT'API</h2>
                     <h3 className="portal-logo-sub-text">Integrate Easier, Faster and More Secure With PowerCARD APIs</h3>
                     </div>
                     <div>
                       <img className="portal-api-logo"   src={CardLogo} />
                     </div>
                    </Col>
                  </Row>
                  <Row>
                  <div className="btn-wrapper">
                        <Button
                          className="btn-icon mb-3 mb-sm-0 btn-explorer"
                          color="info"
                          to="/select-product" tag={Link}
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fa fa-search cust-icon" />
                          </span>
                          <span   className="btn-inner--text no-uppercase">Explore our APIs</span>
                        </Button>
                        <Button
                          className="btn-icon mb-3 mb-sm-0 btn-user-guide"
                          color="info"
                          to="/help" tag={Link}
                         
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-calendar-check cust-icon" />
                          </span>
                          <span   to="/help" tag={Link}   className="btn-inner--text no-uppercase">Request a Demo</span>
                        </Button>
                        <p className="last-update">SandBox Version : 2.3.3 - Last Updated: February 28, 2025</p>
                        <p className="last-update pwc-version">PWC Version : PWC-4.2.4-RC1</p>
                      </div>
                   
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew version-sep"> 
              </div>
            </section>

          </div>
          <section className="section section-lg pt-lg-0 mt--200 section2">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">

                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg main-feature version-page">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/theme/promo-2.jpg")}
                  />
                  <h4 className="hps-creation-date">Enabling innovative payments …</h4>
                  <p className="lead lead-justify">HPS is a multinational company and a leading provider of payment software and solutions for issuers, acquirers, card processors, independent sales organisations (ISOs), retailers, mobile network operators (MNOs), and national & regional switches around the world.
                    <br/>PowerCARD is HPS’s comprehensive suite of solutions that covers the entire payment value chain by enabling innovative payments through its open platform that allows the processing of any transaction coming from any channel, initiated by any means-of-payment. PowerCARD is used by more than 500 institutions in over 95 countries…

                  </p>
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">

                    <h4>Innovation Is key for payments sector</h4>
                    <p>
                    Open business and innovation have been vital to the payments industry, and this trend will continue... It is a cornerstone of financial technology and particularly important to the payments sphere.
                    </p>
                    <p>
                    PowerCARD Connect'API have become an integral part of our digital technology, and the new innovations are exciting to look out for. In fact, Connect'API promote innovations and encourage new partnerships between brands.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                          <img
                              alt="..."
                              className="img-fluid feature"
                              src={require("assets/img/product_solutions.png")}
                            />
                          </div>
                        </div>
                      </li>
                      
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section pb-0 bg-gradient-warning testmonials">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-lg-2 ml-lg-auto" md="6">
                  <div className="position-relative pl-md-5">
                    <img
                      alt="..."
                      className="img-center img-fluid testmonials-image"
                      src={require("assets/img/theme/testmonials.png")}
                    />
                  </div>
                </Col>
                <Col className="order-lg-1" lg="6">
                  <div className="d-flex px-3 text-zone">
                    <div>
                    </div>
                    <div className="pl-4">
                      <h4 className="display-3 text-white">Here's What Our Customers Are Saying About Us.</h4>
                      <p className="text-white">
                      We can say all we like about our PowerCARD products and services but the only people whose opinions we value – and learn from – are our customers…
                      </p>
                    </div>
                  </div>
                  <Card className="shadow shadow-lg--hover mt-5 card-p card-w">
                    <CardBody>
                      <div className="d-flex px-0">
                        <div>
{/*                           <div className="icon icon-shape bg-gradient-success rounded-circle text-white icon-cust icon-position">
                            <i className="ni ni-satisfied" />
                          </div> */}
                        </div>
                        <div className="pl-2">
     {/*                      <h5 className="title text-success">
                          ..The future of financial innovation
                          </h5> */}
                          <UncontrolledCarousel items={items} />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100 ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg bg-gradient-default feedback contact-us">
            <Container className="pt-lg pb-300">
              <Row className="text-center justify-content-center">
                <Col lg="10">
                  <h2 className="display-3 text-white">Reach out to us for help</h2>
                  <p className="lead text-white">
                  Looking for API specific help? Please leave a message with your name and email address, and we will get right back to you.
                  </p>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section section-lg pt-lg-0 section-contact-us">
            <Container>
              <Row className="justify-content-center mt--300">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                   <Form role="form" onSubmit={this.handleSubmit}>
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">CONTACT US</h4>
                      <p className="mt-0">
                      Contact us to obtain access to the sandbox environment
                      </p>
                      {/** Subject */}
                      <FormGroup
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Subject"
                            type="text"
                            name="emailSubject"
                            value={this.state.emailSubject}
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                          />
                        </InputGroup>
                      </FormGroup>
                        {/** Full name */}
                      <FormGroup
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-badge" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Full name"
                            type="text"
                            name="fullName"
                            value={this.state.fullName}
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                          />
                        </InputGroup>
                      </FormGroup>
                        {/** Company */}
                      <FormGroup>
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-building" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Company"
                            type="text"
                            name="companyName"
                            value={this.state.companyName}
                            onFocus={e => this.setState({ nameFocused: true })}
                            onBlur={e => this.setState({ nameFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                          />
                        </InputGroup>
                      </FormGroup>
                       {/** Email */}
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            name="emailAddress"
                            value={this.state.emailAddress}
                            onFocus={e => this.setState({ emailFocused: true })}
                            onBlur={e => this.setState({ emailFocused: false })}
                            onChange={(event) => this.handleUserInput(event)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="emailBody"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                          onChange={(event) => this.handleUserInput(event)}
                          value={this.state.emailBody}
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round btn-contact-us"
                          color="default"
                          size="lg"
                          type="button"
                          disabled={!this.isFormValid()}
                          onClick={this.onSendEmail}
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Container>
            <div className="separator separator-bottom separator-skew version-sep"> </div>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Home;
