import React from "react";
import SwaggerUI from "swagger-ui-react"
import { Link } from "react-router-dom";

import { authenticationService } from 'services/authentication.service';
// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
// reactstrap components
import {
    Row,
    Breadcrumb,
    BreadcrumbItem,
    Button,
    Card,
    Input, ListGroup, ListGroupItem, Badge
} from "reactstrap";
import Tree from "react-animated-tree-v2";

const treeStyles = {
    position: 'absolute',
    top: 160,
    left: 10,
    color: '#5F56BB',
    fill: '#5F56BB',
    width: '20%',
    height: '100%'

}

class WebHook extends React.Component {


  constructor(props) {
    super(props);
      this.state = {
          moduleData:[],
          subApiList:[],
          yamlFile:process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/webhooks/card_create_event.yaml',
          htmlFile:process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/statics/index.html',
          isOpen:false,
          hookUrl:'',
          header_key:'',
          header_value:'',
          headers:[],
          webhookResponse:{},
          relatedEvents:[]
      }
      this.onComplete = this.onComplete.bind(this);
      this.setWebhookUrl = this.setWebhookUrl.bind(this);
      this.setHeaderKey = this.setHeaderKey.bind(this);
      this.setHeaderValue = this.setHeaderValue.bind(this);
      this.addHeader = this.addHeader.bind(this);
      this.resetHeaders = this.resetHeaders.bind(this);
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (!authenticationService.currentUserValue) { 
      this.props.history.push('/');
    }
    this.loadModulesData();
  }
  resetHeaders(){
      this.setState({ headers: []})
  }
  setHeaderKey(event){
      this.setState({ header_key: event.target.value })
  }
  setHeaderValue(event){
        this.setState({ header_value: event.target.value })
  }
  addHeader(){
      if(this.state.header_key !=="" && this.state.header_key!== undefined && this.state.header_key!==null &&
          this.state.header_value !=="" && this.state.header_value!== undefined && this.state.header_value!==null){
          let exist = false ;
          this.state.headers.forEach((v)=>{
              let obj = JSON.parse(JSON.stringify(v));
              if(obj !== null && obj !==undefined){
                  if (obj['key'] === this.state.header_key){
                      exist = true ;
                  }
              }
          })
           if(!exist){
               this.state.headers.push({'key':this.state.header_key,'value':this.state.header_value})
           }
          this.setState({ headers: this.state.headers })
      }
  }
  setWebhookUrl(event) {
        this.setState({ hookUrl: event.target.value })
  }
  onComplete(){
        if(document.getElementsByClassName("authorize")[0]){
            document.getElementsByClassName("authorize")[0].innerHTML = '';
        }
  }
  loadModulesData(){
      let requestOptions = { method: 'GET', headers: { 'Content-Type': 'application/json' }};
      let url = process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/webhooks-descriptor.json';
      fetch(url, requestOptions)
          .then((r) => r.json())
          .then(data => {
              if(data.modules[0]!==undefined && data.modules[0]!==null &&
                  data.modules[0].apis[0]!==undefined && data.modules[0].apis[0]!==null ){
                  this.setState({
                      relatedEvents: data.modules[0].apis[0].relatedEvents,
                      yamlFile : process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/webhooks/'+data.modules[0].apis[0].file,
                  })
              }
              this.setState({
                  moduleData: data.modules
              })
          })
  }
    toggle(){
        this.setState({
         isOpen:!this.state.isOpen
        });
    }

  loadApiData(apiYaml,relatedEvents){
      this.setState({
          yamlFile:process.env.PUBLIC_URL+'/hps/'+this.props.match.params.version+'/'+this.props.match.params.solution+'/'+this.props.match.params.module+'/api/schemas/webhooks/'+apiYaml,
      })
      if(relatedEvents != undefined && relatedEvents!= null && relatedEvents.length >0){
          this.setState({
              relatedEvents: relatedEvents
              }
          )
      }
  }
  render() {
    return (
      <>
        <AppNavbar />
        <main ref="main">
          <section className="section section-shaped section-lg section-bg">
          <Row>
              <div className="breadcrumbs-swagger-wrap">
                    <Breadcrumb className="breadcrumbs-cust breadcrumbs-swagger">
                        <BreadcrumbItem  active>Home</BreadcrumbItem>
                        </Breadcrumb>
                        <Breadcrumb>
                          <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                          <BreadcrumbItem> <Link to="/select-product">Products</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.push(sessionStorage.getItem("selected-version"))}>Releases</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.push(sessionStorage.getItem("selected-solution"))}>Solutions</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.push(sessionStorage.getItem("selected-module"))}>Modules</Link></BreadcrumbItem>
                          <BreadcrumbItem active>Webhooks</BreadcrumbItem>
                        </Breadcrumb>
              </div>
              <hr className="solid"/>
              <hr className="solid-bottom"/>
              <div className="separator separator-bottom separator-skew">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      preserveAspectRatio="none"
                      version="1.1"
                      viewBox="0 0 2560 100"
                      x="0"
                      y="0"
                  >
                      <polygon
                          className="fill-white"
                          points="2560 0 2560 100 0 100"
                      />
                  </svg>
              </div>
              </Row>

              <div className="api-explorer-container">
                  <Row className="api-explorer">
                      <Tree content="Webhooks Catalog"  open style={treeStyles}>
                          {
                              this.state.moduleData.map(e=> (
                                  <Tree   style={{   fontWeight: 'bold' }}  content={e.moduleName} open={e.expanded}>
                                      {
                                          e.apis.map(i=>(
                                              <Tree  style={{   fontWeight: 'normal' }}   itemId={i.file} content={i.name}     onItemClick={(itemId) => this.loadApiData(itemId,i.relatedEvents)}
                                              />
                                          ))
                                      }
                                  </Tree>

                              ))
                          }
                      </Tree>
                  </Row>
                  <Row  className="webhooks-details">
                      <div>
                          <div>
                              <Card className="webhooks-card"><h3 className="event-title">
                                  EVENT DOCUMENTATION
                              </h3></Card>
                          </div>
                          <div>
                              <p className="webhooks-description">By registering webhook endpoints in the PowerCard, you enable connectApi to automatically send <b>Event objects</b> using  POST requests to the registered webhook endpoint hosted by your application. After your webhook endpoint receives the Event, your app can run business backend actions.</p>
                              <h3 className="webhooks-h3">WEBHOOK URL</h3>
                              <hr className="webhooks-hr"/>
                                  <table className="webhooks-table">
                                      <tr className="webhooks-body-param">
                                          <td className="webhooks-td">
                                              <label className="webhooks-label">Hook Url <pre>String</pre></label>
                                          </td>
                                          <td className="webhooks-td">
                                              <label>The url that you want to register to receive the card create event</label>
                                          </td>
                                          <td className="webhooks-td webhooks-td-flex">
                                              <Input onChange={this.setWebhookUrl} value={this.state.hookUrl} className="webhooks-url-input" placeholder="http://url.domain.com/webhooks" type="sm"></Input>
                                              <h4 className="webhook-input-required"></h4>
                                          </td>
                                  </tr>
                              </table>
                          </div>
                          <div className="webhooks-headers-container">
                              <h3 className="webhooks-h3">HEADERS</h3>
                              <hr className="webhooks-hr"/>
                              <table className="webhooks-table-headers">
                                  <tr className="webhooks-headers-inputs">
                                      <td>
                                          <Input onChange={this.setHeaderKey} value={this.state.header_key} className="webhooks-input-header" placeholder="api-key" type="sm"></Input>
                                      </td>
                                      <td className="webhooks-td-2">
                                          <Input onChange={this.setHeaderValue} value={this.state.header_value} className="webhooks-input-header" placeholder="mpo_1MqqbKLt4dXK03v5qaIbiNdC" type="sm"></Input>
                                      </td>
                                      <td className="webhooks-td">
                                          <Button
                                              className="my-4 webhooks-add-btn"
                                              color="primary"
                                              type="button"
                                              size="sm"
                                              onClick={this.addHeader}
                                          >
                                              Add
                                          </Button>
                                          <Button
                                              className="my-4 webhooks-clear-btn"
                                              color="danger"
                                              type="button"
                                              size="sm"
                                              onClick={this.resetHeaders}
                                          >
                                              RESET
                                          </Button>
                                      </td>
                                  </tr>
                              </table>
                              <table className="webhooks-headers-list">
                                  <tr>
                                      <td>headers  : </td>
                                      <td>{JSON.stringify(this.state.headers)}</td>
                                  </tr>
                              </table>
                          </div>
                          <SwaggerUI   defaultModelExpandDepth="1" persistAuthorization="true" url={this.state.yamlFile} displayRequestDuration  docExpansion ="full"  defaultModelExpandDepth="1"  onComplete={() => this.onComplete()} requestInterceptor={req =>  {
                              if(!req.url.includes("yaml")){
                                  req.url= this.state.hookUrl // check for no regression
                                  if(this.state.headers.length>0){
                                      this.state.headers.forEach(e=>{
                                          let key = e['key']
                                          req.headers[key]=e['value']
                                      });
                                  }
                              }
                                return req ;
                          }
                          }/>
                      </div>
                  </Row>
                  <Row className="api-explorer-select">
                      <div className="api-explorer-sub-select">
                          <h4>Related events</h4>
                          <div>
                              <ListGroup className="api-version-wrapper">
                                  {
                                      this.state.relatedEvents.map(e=> (
                                          <ListGroupItem className="api-version">
                                              {
                                                  e.HttpVerb=='PATCH' &&
                                                  <Badge className="api-version-verb badge-patch" color="primary">
                                                      PATCH
                                                  </Badge>
                                              }
                                              {
                                                  e.HttpVerb=='DELETE' &&
                                                  <Badge className="api-version-verb badge-delete" color="danger">
                                                      DELETE
                                                  </Badge>
                                              }
                                              {
                                                  e.HttpVerb=='PUT' &&
                                                  <Badge className="api-version-verb badge-put" color="danger">
                                                      PUT
                                                  </Badge>
                                              }
                                              {
                                                  e.HttpVerb=='GET' &&
                                                  <Badge className="api-version-verb badge-get" color="danger">
                                                      GET
                                                  </Badge>
                                              }
                                              {
                                                  e.HttpVerb=='POST' &&
                                                  <Badge className="api-version-verb badge-post" color="primary">
                                                      POST
                                                  </Badge>
                                              }
                                              <span className="webhooks-related-events">  {e.name} </span>
                                          </ListGroupItem>
                                      ))
                                  }
                              </ListGroup>
                          </div>
                      </div>
                  </Row>
              </div>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default WebHook;
