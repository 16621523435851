import React from 'react'
import ReactPlayer from 'react-player'
import ReactHlsPlayer from 'react-hls-player';


class Player extends React.Component {
    render () {
      return (
          <div className='player-wrapper'>
       {/*    <ReactPlayer
            url={require("assets/video/ConnectAPI.mp4")}
            className='react-player'
            playing
            width='100%'
            height='100%'
            config={{ 
                file: { 
                  attributes: {
                        onContextMenu: e => e.preventDefault()
                    } 
              } 
            }}
          /> */}
          <ReactHlsPlayer
            src={'/videos/stream/ConnectAPI.m3u8'}
            autoPlay={true}
            controls={true}
            width="100%"
            height="auto"
          />,
        </div>
      )
    }
  }
  export default Player