import React from "react";
import { authenticationService } from 'services/authentication.service';
import { auditService } from 'services/audit.service';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Container,
  Row,
  Col,
  Alert,
  Spinner
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { Link } from "react-router-dom";

class Login extends React.Component {
  
  constructor(props,context) {
            super(props);
            this.state = {
                Username: '',
                Password: '',
                failureModal: false,
                errorMessage: '',
                userLockedModal: false,
              }
            this.Username = this.Username.bind(this);
            this.Password = this.Password.bind(this);
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  Username(event) {
       this.setState({ Username: event.target.value })
       this.onError('');
   }
  Password(event) {
     this.setState({ Password: event.target.value })
     this.onError('');
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
      // redirect to home if already logged in
      if (authenticationService.currentUserValue) { 
        this.props.history.push('/');
      }
  }

   onError(value) {
      document.getElementById("errorMessage").innerHTML=value;
   }

   isFormValid = () => {
    const {Username, Password} = this.state
    return Username && Password
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
       this.login();
    }
  }

  login = () => {
    let message = '';
    let userName = document.getElementById('Username').value;
    let pass = document.getElementById('Password').value;
    document.getElementById('login-spinner').style.display = 'block';
    document.getElementById('login-spinner').classList.add("login-spinner");
    authenticationService.login(userName,pass)
                            .then(
                                user => {
                                  if(user){
                                    const userJson = JSON.parse(user);
                                    auditService.trace("Login", "Login page", new Date(), userName,null)
                                        .then(r  => {})
                                    document.getElementById('login-spinner').style.display = 'none';
                                        if(userJson.accountNonLocked === false){
                                          this.toggleModal("userLockedModal");
                                        }else{
                                          window.location.replace("/select-product")
                                        }
                                  }
                                },
                                (error) => {
                                  document.getElementById('login-spinner').style.display = 'none';
                                  let errorType = new String(error);
                                  if(error !== undefined && error !== null && error!="" && !errorType.includes("TypeError")){
                                    console.log(JSON.stringify(JSON.parse(error)))
                                    message = JSON.parse(error);
                                    document.getElementById("errorMessage").innerHTML=message.errorMessage;
                                  }else{
                                    document.getElementById("errorMessage").innerHTML = "Internal error please try again later."
                                  }

                                }
      );
  }
  render() {
    return (
      <>
        <AppNavbar />
                {/* on success model */}
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.userLockedModal}
                  toggle={() => this.toggleModal("userLockedModal")}
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                    Inactive Account
                    </h5>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal("userLockedModal")}
                    >
                      <span aria-hidden={true}>X</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Alert className="alert-default">
                      <span className="alert-inner--text">
                        <strong></strong>
                        <p className="modal-locked-message"> Your Sandbox Account has not been activated yet.</p>
                      </span>
                    </Alert>
                  </div>
                  <div className="modal-footer">
                  </div>
             </Modal>
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default login-bg">

            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0 create-acc-card">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4 text-register">
                        <small>Sign in with credentials</small>
                      </div>
                      <Form role="form" className="form-position">
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-circle-08" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input id="Username" onChange={this.Username} placeholder="Username" type="text"  value={this.state.Username}/>
                          </InputGroup>
                        </FormGroup>
                        <FormGroup>
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-lock-circle-open" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              id="Password"
                              placeholder="Password"
                              type="password"
                              value={this.state.Password}
                              autoComplete="off"
                              onChange={this.Password}
                              onKeyDown={this._handleKeyDown}
                            />
                          </InputGroup>
                        </FormGroup>
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                        </div>
                        <div className="text-center">
                          <Button
                            className="my-4 btn-login-acc"
                            color="primary"
                            type="button"
                            disabled={!this.isFormValid()}
                            onClick={this.login}
                          >
                          
                            Sign in
                          </Button>
                          <Spinner animation="border" className="login-spinner" id="login-spinner"/>
                          <span id="errorMessage"></span>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <small className="forgot-password"></small>
                      </a>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Link
                       href=""
                        className="text-light"
                        to="/forgotPassword" tag={Link}
                       
                      >
                        <small className="create-account-link">Forgot password ?</small>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <div className="separator separator-bottom separator-skew version-sep"></div>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Login;
