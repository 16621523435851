import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import { authenticationService } from 'services/authentication.service';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXTwitter, faLinkedin, faYoutube } from "@fortawesome/free-brands-svg-icons";
// reactstrap components
import {
  UncontrolledCollapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
  Row,
  Col,

  UncontrolledTooltip, DropdownItem
} from "reactstrap";
import { Alert } from "bootstrap";
import {auditService} from "../../services/audit.service";

class AppNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogged: false,
      permissions: []
    };
  }
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    let permis = [];
    if (authenticationService.currentUserValue) {
      let authorities = authenticationService.getUserAuthorities();
      for( let i = 0; i < authorities.length; i++ ) {
        for( let j = 0; j < authorities.length; j++ ) {
          var obj = authorities[i][j];
          permis.push(obj.permission) ;
        }
      }

      this.setState({
        isLogged: true,
        permissions: permis
      });

    }
  }

  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  logout(){
    auditService.trace("Logout", "Click logout", new Date(),authenticationService.getCurrentUser(),null)
        .then(r  => {
          authenticationService.logout();
          window.location.replace("/home");
        },error =>{
          authenticationService.logout();
          window.location.replace("/home");
        })
  }

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  redirectToExternal(new_url) {
    window.open(new_url, '_blank');
  };
  render() {
    return (
        <>
          <header className="header-global">
            <Navbar
                className="navbar-main navbar-transparent navbar-light headroom"
                expand="lg"
                id="navbar-main"
            >
              <Container>
                <NavbarBrand  onClick={() => this.scrollToTop()} className="mr-lg-5" to="/" tag={Link}>
                  <img
                      className="web-meeting"
                      onClick={()=>this.redirectToExternal('https://wemeeting2023.com/agenda/')}
                      alt="..."
                      src={require("assets/img/brand/webmeeting.png")}
                  />
                  <img
                      alt="..."
                      src={require("assets/img/brand/hps-logo.png")}
                  />
                </NavbarBrand>
                <button className="navbar-toggler" id="navbar_global">
                  <span className="navbar-toggler-icon" />
                </button>
                <UncontrolledCollapse
                    toggler="#navbar_global"
                    navbar
                    className={this.state.collapseClasses}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                  <div className="navbar-collapse-header">
                    <Row>
                      <Col className="collapse-brand" xs="6">
                        <Link to="/home">
                          <img
                              alt="..."
                              src={require("assets/img/brand/hps-logo.png")}
                          />
                        </Link>
                      </Col>
                      <Col className="collapse-close" xs="6">
                        <button className="navbar-toggler" id="navbar_global">
                          <span />
                          <span />
                        </button>
                      </Col>
                    </Row>
                  </div>
                  <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                    <UncontrolledDropdown nav>
                      {this.state.permissions.indexOf("manage_user") > -1 ? (
                              <DropdownToggle nav  to="/users" tag={Link}>
                                <i className="ni ni-ui-04 d-lg-none mr-1" />
                                <a className="nav-link-inner--text"   to="/users" tag={Link}>User Managment</a>
                              </DropdownToggle>
                          ):
                          (
                              <DropdownToggle nav>
                                <i className="ni ni-ui-04 d-lg-none mr-1" />
                                <a className="nav-link-inner--text"></a>
                              </DropdownToggle>
                          )
                      }
                    </UncontrolledDropdown>

                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Community
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem  to="/upcoming-events" tag={Link}>HPS at 2025 Events </DropdownItem>
                        <DropdownItem to="/upcoming-events" tag={Link}>Previous Events in 2021</DropdownItem>
                        <DropdownItem to="/press-release" tag={Link}>Press Releases </DropdownItem>
                      </DropdownMenu>

                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        About
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem to="/about" tag={Link}>About HPS</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                  <Nav className="align-items-lg-center ml-lg-auto social-media-items d-flex" navbar style={{ gap: "12px" }}>
                    {/* Twitter / X */}
                    <NavItem>
                      <NavLink href="https://twitter.com/HPS_worldwide" target="_blank"
                               style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "42px", height: "42px", transition: "all 0.3s ease-in-out" }}
                               onMouseEnter={(e) => e.currentTarget.style.background = "rgba(0, 0, 0, 0.1)"}
                               onMouseLeave={(e) => e.currentTarget.style.background = "transparent"}
                      >
                        <FontAwesomeIcon icon={faXTwitter} size="lg" style={{ color: "#000" }} />
                      </NavLink>
                    </NavItem>

                    {/* LinkedIn */}
                    <NavItem>
                      <NavLink href="https://www.linkedin.com/company/hps" target="_blank"
                               style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "42px", height: "42px", transition: "all 0.3s ease-in-out" }}
                               onMouseEnter={(e) => e.currentTarget.style.background = "rgba(0, 0, 0, 0.1)"}
                               onMouseLeave={(e) => e.currentTarget.style.background = "transparent"}
                      >
                        <FontAwesomeIcon icon={faLinkedin} size="lg" style={{ color: "#0077B5" }} />
                      </NavLink>
                    </NavItem>

                    {/* YouTube */}
                    <NavItem>
                      <NavLink href="https://www.youtube.com/channel/UCG8SC8iSUZlsqR9oOyvG7nA" target="_blank"
                               style={{ display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "50%", width: "42px", height: "42px", transition: "all 0.3s ease-in-out" }}
                               onMouseEnter={(e) => e.currentTarget.style.background = "rgba(0, 0, 0, 0.1)"}
                               onMouseLeave={(e) => e.currentTarget.style.background = "transparent"}
                      >
                        <FontAwesomeIcon icon={faYoutube} size="lg" style={{ color: "#FF0000" }} />
                      </NavLink>
                    </NavItem>
                  </Nav>

                </UncontrolledCollapse>
                <label className="current-user">{authenticationService.getCurrentUser()}</label>
                {this.state.isLogged ? (
                    <Button className="btn-sign-out" color="warning" type="button"  onClick={this.logout}>
                      Sign out {this.state.isLogged}
                    </Button>
                ) : (
                    <Button className="btn-create-acc" color="warning" type="button"  to="/register" tag={Link}>
                      Create an account
                    </Button>
                )}
              </Container>
            </Navbar>
          </header>
        </>
    );
  }
}

export default AppNavbar;