import React from "react";
import { Link } from "react-router-dom";
import { configService } from 'services/config.service';
import { authenticationService } from 'services/authentication.service';
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Alert,
  Modal,
  Spinner,
  Breadcrumb, BreadcrumbItem
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import {auditService} from "../services/audit.service";

class Solution extends React.Component {

  constructor() {
    super();
    this.state = {
      wsConfigs : [],
      errorMessage: ''
    }
  }
  onSelectSolution = (solutionName,description) =>{
    sessionStorage.setItem("selected-solution-description",description);
    auditService.trace("Select ConnectAPI Solution", "Select page", new Date(),authenticationService.getCurrentUser(),solutionName)
        .then(r  => {})
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    if (!authenticationService.currentUserValue) { 
      this.props.history.push('/');
    }
    this.onConfigLoad();
  }
  toggleFailModal =  (state,msg) => {
    this.setState({
      [state]: !this.state[state],
      errorMessage : msg
    });
    if(state === "close"){
      authenticationService.logout();
      window.location.replace("/login");
     }
  };
  onConfigLoad = () => {
    sessionStorage.setItem("selected-solution",window.location.pathname)
    document.getElementById('solution-spinner').style.display = 'block';
    document.getElementById('solution-spinner').classList.add("solution-spinner");
    configService.getAllSolutions(this.props.match.params.id)
    .then(response => {
      let values = response;
      this.setState({
        wsConfigs: values
      });
      var el = document.getElementById('solution-spinner');
      if(el){
        el.style.display = 'none';
      }
    }, (error) => {
      var el = document.getElementById('solution-spinner');
      if(el){
        el.style.display = 'none';
      }
        console.log("ERROR : "+JSON.stringify(error))
        if(error && error.status === 401){
          this.toggleFailModal("failureModal","Your session is expired please login again.");
        }else{
          this.toggleFailModal("failureModal","Internal error please try again later.");
        }
    });
  }
  renderSwitch(param) {
    switch(param.toUpperCase()) {
      case 'POWERCARD-ISSUER V4':
        return   <img className="module-icon"  src={require("assets/img/cards/m11.png")} />;
      case 'MC-ADDED-VALUE-SERVICES':
        return   <img className="module-icon"  src={require("assets/img/cards/mastercard.png")} />;
      case 'VISA-ADDED-VALUE-SERVICES':
        return   <img className="module-icon"  src={require("assets/img/cards/visa logo.png")} />;
      case 'POWERCARD-ISSUER':
        return   <img className="module-icon"  src={require("assets/img/cards/m1.png")} />;
      case 'POWERCARD-ACQUIRER':
          return   <img className="module-icon"  src={require("assets/img/cards/m2.png")} />;
      case "POWERCARD-SWITCH":
          return   <img className="module-icon"  src={require("assets/img/cards/m3.png")} />;
      case "POWERCARD-XPOS":
            return   <img className="module-icon"  src={require("assets/img/cards/m4.png")} />;
      case "POWERCARD-ATM":
            return   <img className="module-icon"  src={require("assets/img/cards/m5.png")} />;
      case "POWERCARD-SWITCH V4":
            return   <img className="module-icon"  src={require("assets/img/cards/m33.png")} />;
      default:
        return   <img className="module-icon"  src={require("assets/img/cards/m1.png")} />;
    }
  }
  render() {
    const baseUrl = "assets/img/cards/";
    return (
      <>
        <AppNavbar />
        {/* on failure model */}
        <Modal
          className="modal-dialog-centered"
          isOpen={this.state.failureModal}
          toggle={() => this.toggleFailModal("failureModal")}
        >
          <div className="modal-header">
            <h5 className="modal-title modal-title-error" id="exampleModalLabel">
             Sorry!
            </h5>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.toggleFailModal("close")}
            >
              <span aria-hidden={true}>X</span>
            </button>
          </div>
          <div className="modal-body">
           {/** <p className="modal-message-error"></p> */}
            <Alert color="warning">
              <span className="alert-inner--text">
                <strong></strong>
                <p className="modal-message-error">{this.state.errorMessage}</p>
              </span>
           </Alert>
              
          </div>
          <div className="modal-footer">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              className="btn-close-modal"
              onClick={() => this.toggleFailModal("close")}
            >
              Close
            </Button>
          </div>
        </Modal>
        <main className="profile-page version-page" ref="main">
          <section className="section-profile-cover section-shaped my-0 main-version-bg"> 
          <div className="breadcrumbs-wrap">
                    <Breadcrumb className="breadcrumbs-cust">
                        <BreadcrumbItem   active>Home</BreadcrumbItem>
                        </Breadcrumb>
                        <Breadcrumb>
                          <BreadcrumbItem><Link to="/home">Home</Link></BreadcrumbItem>
                          <BreadcrumbItem> <Link to="/select-product">Products</Link></BreadcrumbItem>
                          <BreadcrumbItem><Link onClick={() => this.props.history.push(sessionStorage.getItem("selected-version"))}>Releases</Link></BreadcrumbItem>
                          <BreadcrumbItem active>Solutions</BreadcrumbItem>
                        </Breadcrumb>
          </div>  
          </section>
          <section className="section main-module-sub-bg">
            <Container>
              <Card className="card-profile mt--300">
              <Row className="justify-content-center">
              <Row className="justify-content-center">
                  <Col lg="12">
                      <div >
                      <h2 className="version-title-wrap">PowerCARD Connect’ API Solutions</h2>
                      <p className="pg-version-sub-title">
                         PowerCARD Connect’ API is designed to match FinTechs creating emerging payments solutions directly with providers, enabling end users to benefit from the latest technology in the payments industry, such as mobile and contactless payments, tokenisation and biometrics, thus delivering an enhanced digital-first and secure user experience for both consumers and merchants.
                      </p>
                      </div>
                    </Col>
                    </Row>
                  <Col lg="12">
                    <Row className="row-grid">
                    <Spinner animation="border" className="solution-spinner" id="solution-spinner"/>
                     {this.state.wsConfigs.map(config => (
                     <Col lg="4"  key={config.id}>

                     <Card className="card-lift--hover shadow border-0">
                     <label className="v-lable">{config.wsVersion.name}</label>
                               <CardBody className="py-5 py-style">
                             
                                     <div className="icon icon-shape  rounded-circle mb-4">
                                      {this.renderSwitch(config.name)}
                                      {config.latestVersion ? (
                                            <Badge color="primary" pill className="mr-1 module-name v4-latest-version">
                                            {config.name}
                                            </Badge>
                                      ):(
                                        <Badge color="primary" pill className="mr-1 module-name">
                                        {config.name}
                                        </Badge>
                                      )}
                                     
                                     </div>
                                    
                                     <p className="description mt-3 pg-version-card-desc">
                                       {config.description.substring(0,160)}...
                                     </p>
                                     <div>
                                      
       
                                     </div>
                                     <div>
                                     {config.latestVersion ? (
                                      <Button
                                          onClick={()=>this.onSelectSolution(config.name,config.description)}
                                         disabled={!config.enabled}
                                       className="mt-4 btn-select-module v4-latest-version"
                                       color="default"
                                       to={"/select-module/"+config.id+"/"+config.name} tag={Link}
                                      >
                                      <span className="btn-inner--icon">
                                             <i className="ni ni-bold-right v4-btn-select-icon" />
                                            </span>
                                      <span className="btn-inner--text">Explore</span> 
                                     </Button>
                                     ):(
                                      <Button
                                          onClick={()=>this.onSelectSolution(config.name,config.description)}
                                         disabled={!config.enabled}
                                       className="mt-4 btn-select-module"
                                       color="primary"
                                       to={"/select-module/"+config.id+"/"+config.name} tag={Link}
                                      >
                                      Explore 
                                     </Button>
                                     )}
                                     </div>
                                     
                                 </CardBody>
                               </Card>
                              </Col>
                              ))} 

                    </Row>
                  </Col>
                </Row>
              </Card>
            </Container>
           
          </section>
         
        </main>
        <div className="separator separator-bottom separator-skew select-api-version-sep"> </div>
        <SimpleFooter />
      </>
    );
  }
}

export default Solution;
