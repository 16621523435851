import React from "react";
import { authenticationService } from 'services/authentication.service';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Container,
  Row,
  Col,
  Alert,
  Spinner
} from "reactstrap";

// core components
import AppNavbar from "components/Navbars/AppNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
class ForgotPassword extends React.Component {
  
  constructor(props,context) {
            super(props);
            this.state = {
                email:'',
                failureModal: false,
                errorMessage: '',
                changePasswordModal: false,
              }
            this.setEmail = this.setEmail.bind(this);
            this._reCaptchaRef = React.createRef();
  }
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  setEmail(event) {
       this.setState({ email: event.target.value })
       document.getElementById("invalid-email").innerHTML='';
   }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
      // redirect to home if already logged in
      if (authenticationService.currentUserValue) { 
        this.props.history.push('/');
      }
  }
  
   isFormValid = () => {
    const {email} = this.state
    return email
  }
  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
       this.resetPassword();
    }
  }
  handleChange = value => {
    this.resetPassword();
  }
  onResetPassword = () => {
    this._reCaptchaRef.current.execute();
  }
  resetPassword = () => {
    let message = '';
    let email = document.getElementById('email').value;
    document.getElementById('reset-spinner').style.display = 'block';
    document.getElementById('reset-spinner').classList.add("reset-spinner");
    authenticationService.resetPassword(email)
                            .then(
                                data => {
                                  if(data){
                                        document.getElementById('reset-spinner').style.display = 'none';
                                        this.toggleModal("changePasswordModal");
                                  }
                                },
                                (error) => {
                                  document.getElementById('reset-spinner').style.display = 'none';
                                  let errorType = new String(error);
                                  if(error !== undefined && error !== null && error!="" && !errorType.includes("TypeError")){
                                    //console.log(JSON.stringify(JSON.parse(error)))
                                    message = JSON.parse(error);
                                    this.toggleModal("changePasswordModal");
                                  }else{
                                    document.getElementById("invalid-email").innerHTML = "Internal error please try again later."
                                  }

                                }
      );
  }
  render() {
    return (
      <>
        <AppNavbar />
                <Modal
                  className="modal-dialog-centered"
                  isOpen={this.state.changePasswordModal}
                  toggle={() => this.toggleModal("changePasswordModal")}
                >
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                    Password Reset
                    </h5>
                    <button
                      aria-label="Close"
                      className="close"
                      data-dismiss="modal"
                      type="button"
                      onClick={() => this.toggleModal("changePasswordModal")}
                    >
                      <span aria-hidden={true}>X</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Alert className="alert-default">
                      <span className="alert-inner--text">
                        <strong></strong>
                        <p className="modal-locked-message">An email has been sent to with instructions for resetting your password.</p>
                        <p className="modal-locked-message">This email may take a few minutes to arrive in your inbox.</p>
                      </span>
                    </Alert>
                  </div>
                  <div className="modal-footer">
                  </div>
             </Modal>
        <main ref="main">
          <section className="section section-shaped section-lg">
            <div className="shape shape-style-1 bg-gradient-default login-bg">

            </div>
            <Container className="pt-lg-7">
              <Row className="justify-content-center">
                <Col lg="5">
                  <Card className="bg-secondary shadow border-0 create-acc-card">
                    <CardBody className="px-lg-5 py-lg-5">
                      <div className="text-center text-muted mb-4 text-register">
                        <h3>Reset your password</h3>
                        <p className="lost-password-txt">Lost your password? Please enter your email address.You will receive a link to create a new password via email</p>
                      </div>
                      <Form role="form" className="form-position">
                        <ReCAPTCHA
                            style={{ display: "inline-block" }}
                            theme="dark"
                            size="invisible"
                            ref={this._reCaptchaRef}
                            sitekey="6LdVRYEfAAAAAA-m-lctYvYrmVWnZ3yT3TMKJsQH"
                            onChange={this.handleChange}
                        />
                        <FormGroup className="mb-3">
                          <InputGroup className="input-group-alternative">
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="ni ni-circle-08" />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input id="email" onChange={this.setEmail} placeholder="Email" type="text"  value={this.state.email}/>
                          </InputGroup>
                        </FormGroup>
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <input
                            className="custom-control-input"
                            id=" customCheckLogin"
                            type="checkbox"
                          />
                        </div>
                        <div className="text-center">
                          <Button
                            className="my-4 btn-login-acc btn-reset-password"
                            color="primary"
                            type="button"
                            disabled={!this.isFormValid()}
                            onClick={this.onResetPassword}
                          >
                            RESET PASSWORD
                          </Button>
                          <Spinner animation="border" className="reset-spinner" id="reset-spinner"/>
                          <span id="invalid-email"></span>
                        </div>
                      </Form>
                    </CardBody>
                  </Card>
                  <Row className="mt-3">
                    <Col xs="6">
                      <a
                        className="text-light"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <small className="forgot-password"></small>
                      </a>
                    </Col>
                    <Col className="text-right" xs="6">
                      <Link
                       href=""
                        className="text-light"
                        to="/login" tag={Link}
                       
                      >
                        <small className="create-account-link">Login</small>
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <div className="separator separator-bottom separator-skew version-sep"></div>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default ForgotPassword;
