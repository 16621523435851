import React from "react";
import { Container, Row, Col } from "reactstrap";
import { FaCalendarAlt, FaFlag, FaUserTie, FaGlobe } from "react-icons/fa";

export default function AboutSection() {
    const stats = [
        {
            icon: <FaCalendarAlt size={50} className="icon-style" />,
            value: "30",
            label: "Years",
            description: "We have three decades of payments expertise led by cutting-edge technology"
        },
        {
            icon: <FaFlag size={50} className="icon-style" />,
            value: "+500",
            label: "Users in 95+ countries",
            description: "We have countless success stories and long-standing partnerships across sectors"
        },
        {
            icon: <FaUserTie size={50} className="icon-style" />,
            value: "+1400",
            label: "Experts",
            description: "We have 1400+ experts and an extensive network of partners to scale"
        },
        {
            icon: <FaGlobe size={50} className="icon-style" />,
            value: "Global",
            label: "Footprint",
            description: "With offices in over 10 countries, with local delivery centers and support teams."
        }
    ];

    const styles = `
    /* Animation de fade-in pour l'ensemble de la page */
    .page-container {
      animation: pageFadeIn 1s ease-out forwards;
    }
    @keyframes pageFadeIn {
      from { opacity: 0; }
      to { opacity: 1; }
    }
    
    /* Animation de fade-in + slide-up pour chaque carte */
    .stat-box {
      animation: fadeInUp 0.6s ease-out forwards;
      opacity: 0;
    }
    @keyframes fadeInUp {
      from {
        opacity: 0;
        transform: translateY(20px);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .icon-container {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: #0072ff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 15px;
      position: relative;
      overflow: hidden;
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    }
    .icon-container::after {
      content: "";
      position: absolute;
      top: -3px;
      left: -3px;
      right: -3px;
      bottom: -3px;
      border-radius: 50%;
      background: conic-gradient(from 0deg, #ff8a00, #e52e71, #ff8a00);
      z-index: -1;
      filter: blur(5px);
      opacity: 0;
      transition: opacity 0.3s ease;
    }
    .icon-container:hover {
      transform: scale(1.1);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    }
    .icon-container:hover::after {
      opacity: 1;
    }
    .icon-style {
      color: #ffd700;
      transition: transform 0.6s ease, filter 0.3s ease;
    }
    .icon-container:hover .icon-style {
      transform: rotate(360deg);
      filter: drop-shadow(0 0 10px #ffd700);
    }
  `;

    return (
        <Container className="py-5 page-container">
            {/* Injection du CSS intégré */}
            <style>{styles}</style>
            <Row className="justify-content-center text-center">
                {stats.map((stat, index) => (
                    <Col key={index} md={3} sm={6} xs={12} className="mb-4">
                        <div className="stat-box">
                            <div className="icon-container">{stat.icon}</div>
                            <h2 className="stat-value">{stat.value}</h2>
                            <h3 className="stat-label">{stat.label}</h3>
                            <p className="stat-description">{stat.description}</p>
                        </div>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}
